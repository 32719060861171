import React, { useCallback } from "react"
import { Link } from "gatsby"

import Style from "./Footer.module.css"
import Section from "../Section/Section"

const Footer: React.FC = () => {

    const scrollToTop = useCallback(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <footer className={Style.footer}>
            <Section className="bg-brown-dark" sectionsmall>
                <div className="flex flex-col sm:flex-row p-0">
                    <div className="w-full md:w-1/2 lg:w-1/3 md:p-0">
                        <ul>
                            <li>Lindsey &amp; friends</li>
                            <li>Hundeträume</li>
                            <li className="street">Blücherplatz 4</li>
                            <li className="city">32139 Spenge</li>
                            <li className="mt-6">
                                Tel:{" "}
                                <a href="tel:+495225879288" className="no-wrap">
                                    0&nbsp;52&nbsp;25&nbsp;&ndash;&nbsp;87&nbsp;92&nbsp;88
                                </a>
                            </li>
                            <li>
                                Mail:{" "}
                                <a href="mailto:info@lindseyandfriends.de">
                                    info@lindseyandfriends.de
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 pt-3 md:p-0">
                        <ul>
                            <li>Öffnungszeiten</li>
                            <li className="mt-6">
                                Montags, Mittwochs bis Freitags:
                            </li>
                            <li>10:00 bis 18:00 Uhr</li>
                            <li className="mt-6">Samstags:</li>
                            <li>10:00 bis 14:00 Uhr</li>
                            <li className="mt-6">Dienstags geschlossen</li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 pt-3 md:p-0 list-inside pl-4 sm:pl-0">
                        <ul className="list-disc">
                            <li className="pb-6">
                                <Link to="/impressum">Impressum</Link>
                            </li>
                            <li className="pb-6">
                                <Link to="/datenschutz">Datenschutz</Link>
                            </li>
                            <li className="pb-6">
                                <a href="https://www.facebook.com/ziegenbruchniehaus">
                                    Facebook
                                </a>
                            </li>
                            <li className="pb-6">
                                <a href="https://www.instagram.com/_lindsey_and_friends_/">
                                    Instagram
                                </a>
                            </li>
                            <li className="pb-6 text-white hover:text-gold">
                                <button onClick={scrollToTop}>
                                    nach oben &uarr;
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Section>
        </footer>
    )
}

export default Footer
